import { PLANNING_URL } from '../../modules/config.module';
import './Partners.scss';

export default function Partners() {

    return (
        <div className='Partners'>
                <div className='our-partners'>
                    <div className='title-ctn'>
                        <img src="apple-emojis/sunglasses.png"
                            alt='Emojis avec des lunettes de soleil.'/>
                        <h1>Nos partenaires</h1>
                        <img src="apple-emojis/sunglasses.png"
                            alt='Emojis avec des lunettes de soleil.'/>
                    </div>
                    <img src="euratechnologies.svg" 
                        alt="Logo officiel d'Euratechnologies."/>
                    <div className='share-ctn'>
                        <p>Vous souhaitez rejoindre l'aventure Caelus ?</p>
                        <p className='link'>
                            <a href={PLANNING_URL}
                                rel="noreferrer"
                                target="_blank">Prenez rendez-vous</a></p>
                    </div>
                </div>
                <div className='stella-ctn'>
                    <div className='title-ctn'>
                        <img src='stella.png'
                            alt="Logo officiel de l'application Stella." />
                        <h1>Stella</h1>
                    </div>
                    <h2>Bientôt plus d'informations</h2>
                    <img src="hub.png"  
                        className='hubOne' 
                        alt='Emojis hub.'/>
                    <img src="hub.png"  
                        className='hubTwo'
                        alt='Emojis hub.' />
                </div>
                <p>Caelus Company @2024</p>
            </div>
    );
}