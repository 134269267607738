import './Button.scss';
import {PLANNING_URL} from '../../modules/config.module';

export default function Button({title = "", display = true}) {

    if (!display) return (<></>);
    return (
        <a href={PLANNING_URL}
            rel="noreferrer"
            target="_blank">
            <div className="Button">
                <h1>{title}</h1>
            </div>
        </a>
    );
}