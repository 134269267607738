import { PLANNING_URL } from '../../modules/config.module';
import './Navbar.scss';

export default function Navbar({current = 0, goTo = () => {},}) {

    return (
        <>
            <div className={`navbar-ctn ${current > 0 ? 'little' : ''}`}>
                <h3>Le porte clé de vos idées, la clé de votre succès</h3>
                <ul className='nav-ctn'>
                    <li onClick={() => {goTo(1)}}
                        className={`${(current >= 1 && current < 6 && window.innerWidth < 1100) || (window.innerWidth >= 1100 && current >= 1 && current < 4) ? 'current' : ''}`}>Cirrus</li>
                    <li onClick={() => {goTo(window.innerWidth < 1100 ? 6 : 4)}}
                        className={`${(current >= 6 && current < 9 && window.innerWidth < 1100) || (window.innerWidth >= 1100 && current >= 4 && current < 7) ? 'current' : ''}`}>Nos valeurs</li>
                    <li>
                        <a href={PLANNING_URL}
                            rel="noreferrer"
                            target="_blank">
                        Prendre rendez-vous
                        </a>
                    </li>
                </ul>
            </div>
            <img src="logo192.png"
                alt='Logo officiel de Caelus Company.'
                className={`laptop-logo ${current > 0 ? 'little' : ''}`} />
        </>
    );
}