import './Review.scss';

export default function Review({text = "", emoji = "clock", altemoji = "Une horloge."}) {

    return (
        <div className="Review">
            <div className='ctn'>
                <div className='violet'></div>
                <div className='white'></div>
                <div className='text-ctn'>
                    <p>{text}</p>
                </div>
                <div className='first-g'><p>"</p></div>
                <div className='second-g'><p>"</p></div>
                <img src={"apple-emojis/"+emoji+".png"} 
                     alt={altemoji}/>

            </div>
        </div>
    );
}