import './Error.scss';

export default function Error({error = "404"}) {

    return (
        <div className="Error">
            <div className='ctn'>
                <h1>Oops ! Tu as l'air perdu ...</h1>
                <p>Erreur n°{error}</p>
            </div>
        </div>
    );
}