const autoScrollTo = ({index = 0, currentSection, innerHeight, setCurrent = () => {}}) => {
    const screenHeight = innerHeight;
    currentSection.current = index;
    setCurrent(index)
    window.scrollTo({
        top: (index) * screenHeight,
        behavior: 'smooth',
    });
}

const handleScroll = ({innerHeight, currentSection, lastScrollPosition, setCurrent = () => {}}) => {
    if (window.scrollY !== lastScrollPosition) {
        const screenHeight = innerHeight;
        const oldSection = currentSection.current;
        const nextTreshold = (screenHeight*oldSection) + (screenHeight*0.15);
        const previousTreshold = (screenHeight*oldSection) - (screenHeight*0.15);

        if (window.scrollY >= nextTreshold) {
            let newSection = Math.trunc(window.scrollY/screenHeight)+1;
            currentSection.current = newSection;
            setCurrent(newSection)
        } else if (window.scrollY <= previousTreshold) {
            let newSection = Math.trunc(window.scrollY/screenHeight);
            currentSection.current = newSection;
            setCurrent(newSection)
        }
        lastScrollPosition.current = window.scrollY;
    }
    
};

const addScrollListener = ({innerHeight, currentSection, lastScrollPosition, setCurrent, onChangedInnerHeight = false}) => {
    window.removeEventListener('scroll', () => {});    
    if (onChangedInnerHeight) {
        handleScroll({innerHeight:innerHeight, currentSection:currentSection, lastScrollPosition:lastScrollPosition, setCurrent:setCurrent});
    }
    window.addEventListener('scroll', () => {handleScroll({innerHeight:innerHeight, currentSection:currentSection, lastScrollPosition:lastScrollPosition, setCurrent:setCurrent})}, false);

}

const addResizeListener = ({setInnerHeight = () => {}}) => {
    let timeout;
    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setInnerHeight(window.innerHeight)
      }, 200);
    });
}


module.exports = {autoScrollTo, handleScroll, addScrollListener, addResizeListener};