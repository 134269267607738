import { Route, Routes } from "react-router-dom";
import Main from "../pages/Main/Main";
import Error from "../pages/Error/Error";

const RoutingModule = () => {
    return (
    <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/401" element={<Error  error="401"/>} />
        <Route path="/400" element={<Error  error="400"/>} />
        <Route path="/402" element={<Error  error="402"/>} />
        <Route path="/403" element={<Error  error="403"/>} />
        <Route path="/*" element={<Error />} />
    </Routes>);
}

export default RoutingModule;