import './Card.scss';

export default function Card({title = "", content = []}) {

    return (
        <div className="Card">
            <div className='content-ctn'>
                <h1>{title}</h1>
                {content.map((c,key) => {
                    return (
                        <p  key={"cardContent"+key}>{c}</p>)
                } )}
            </div>
        </div>
    );
}