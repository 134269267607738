import { PLANNING_URL } from '../../modules/config.module';
import './Home.scss';

export default function Home({goTo = () => {}}) {

    return (
        <div className='Home'>
            <ul className='nav-ctn'>
                <li onClick={() => {goTo(1)}}>Cirrus</li>
                <li onClick={() => {goTo(window.innerWidth < 1100 ? 6 : 4)}}>Nos valeurs</li>
                <li>
                    <a href={PLANNING_URL}
                        rel="noreferrer"
                        target="_blank">
                    Prendre rendez-vous
                    </a>
                </li>
            </ul>
            <div className='c-ctn'>
                <div className='cmenu first' />
                <div className='cmenu second' />
                <div className='cmenu third' />
                <div className='c1' />
                <div className='c2' />
                <div className='c3' />
            </div>
            <div className='title'>
                <img    src="caelus_blanc_transparent.svg" 
                        alt="Logo officiel de Caelus, réprésentant à la fois la planète Uranus et une feuille."/>
                <h1 className='caelus-app-name'>Caelus</h1>
            </div>

            <div className='subtitle'>
                <p>La <b>suite ultime d'applications</b> qui va booster votre productivité.</p>
            </div>
        </div>
    );
}