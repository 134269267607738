import './Values.scss';

export default function Values({current = 0}) {

    return (
        <div className='Values'>
            <div className='sub-values-ctn'>
                <h1>Nos valeurs</h1>
                <div className='ctn'>
                    <div className={`img gouvernance ${current >= 6 || (window.innerWidth >= 1100 && current >= 4) ? 'current' : ''}`}></div>
                    <div className='content-ctn'>
                        <h1>Gouvernance des données</h1>
                        <p>Reprenez le contrôle de vos données en optant pour Caelus.</p>
                        <p>Notre fournisseur français de Cloud Computing certifie un niveau de sécurité optimal ainsi que l'application des réglementations françaises et européennes sur vos données.</p>
                        <p>Chaque donnée, stockée sur des serveurs français, vous permettra de localiser rapidement où vos données sont stockées.</p>
                    </div>
                </div>
            </div>
            <div className='sub-values-ctn'>
                <h1>Nos valeurs</h1>
                <div className='ctn'>
                    <div className={`img ecologie ${current >= 7 || (window.innerWidth >= 1100 && current >= 5) ? 'current' : ''}`}></div>
                    <div className='content-ctn'>
                        <h1>Écologie et GreenIT</h1>
                        <p>Et si économies rimaient avec écologie ?</p>
                        <p>Les applications et services utilisés par nos applications permettent un partage et une optimisation des ressources informatiques de cloud computing.</p>
                        <p>Grâce à cela, vous payez seulement pour votre consommation en ressource*.</p>
                        <p>Si vous ne consommez pas beaucoup de ressources, il est logique que vous ne payez pas beaucoup et que vous ne polluez beaucoup.</p>
                    </div>    
                </div>
            </div>
            <div className='sub-values-ctn'>
                <h1>Nos valeurs</h1>
                <div className='ctn'>
                    <div className={`img simple ${current >= 8 || (window.innerWidth >= 1100 && current >= 6) ? 'current' : ''}`}></div>
                    <div className='content-ctn'>
                        <h1>Simple et intuitif</h1>
                        <p>Cirrus, c'est la puissance du Cloud Computing à portée de mains.</p>
                        <p>Plus besoin de longues et coûteuses formations pour pouvoir accéder et utiliser la puissance des ressources du cloud computing.</p>
                        <p>Nos interfaces sont réalisées à partir des retours de nos utilisateurs finaux pour que l'utilisation reste simple et intuitive pour tous !</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

