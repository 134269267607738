import './Cirrus.scss';
import Button from '../Button/Button';
import Card from '../Card/Card';
import Review from '../Review/Review';

export default function Cirrus({current = 0}) {
    
    return (
        <div className='Cirrus'>
            <div className={`cirrus-title ${current >= 1 ? 'current' : ''}`}>
                <h1>Cirrus</h1>
                <p>Vos applications actuelles en plus rapides et en moins chères.</p>
            </div>
            <div className={`green-ctn ${current >= 2 ? 'current' : ''}`}>
                <img    src="cirrus_blanc_transparent.svg" 
                        alt="Logo officiel de Cirrus, réprésentant à la fois une aurore boréale et une feuille."/>
                <div className='grey-ctn'>
                    <div className='text-ctn'>
                        <p>C'est un fait, installer des applications sur son ordinateur personnel nous fait perdre énormément de temps. Et c'est sans compter sur les incompatibilités matérielles, physiques ou logicielles.</p>
                    </div>
                    
                    <div className='sub-green-ctn'>
                        <div className='title-ctn'>
                            <p>Cela arrive encore trop souvent ...</p>
                            <img src="apple-emojis/sad.png"
                                alt='Emojis qui pleure en souriant'/>
                        </div>
                        <Review text="Nous perdons un temps considérable à installer les outils pour faire le TP proposé par le professeur ..."
                                emoji="clock"
                                altemoji="Un chronomètre qui montre qu'on perd beaucoup de temps à installer des outils."/>
                        <Review text="Mon ordinateur n'est pas compatible avec les outils nécessaires pour effectuer le TP ..."
                                emoji="computer"
                                altemoji="Un ordinateur qui montre un ordinateur, incompatible avec de nombreux logiciels."/>
                        <Review text="Mon ordinateur n'a plus de batterie ... Si seulement je pouvais prendre ma tablette et reprendre mon travail à l'endroit exact où je l'ai laissé ?"
                                emoji="low-battery"
                                altemoji="Pile rouge presque vide de sa batterie."/>
                    </div>

                    <div className='test-ctn'>
                        <div className={`emojis ${window.innerWidth >= 1100 ? 'not': ''}`}>
                            <img src="apple-emojis/clock.png"
                                alt='Emoji chronomètre.'
                                className={`one ${current >= 3 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/clock.png"
                                alt='Emoji chronomètre.'
                                className={`two ${current >= 3 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/clock.png"
                                alt='Emoji chronomètre.'
                                className={`three ${current >= 3 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/clock.png"
                                alt='Emoji chronomètre.'
                                className={`four ${current >= 3 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/clock.png"
                                alt='Emoji chronomètre.'
                                className={`five ${current >= 3 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/clock.png"
                                alt='Emoji chronomètre.'
                                className={`six ${current >= 3 ? "animated" : "not-animated"}`} />
                        </div>
                        <h3>Nous avons la solution !</h3>
                        <Card   title='Apprendre sans attendre'
                                content={["Nous mettons à disposition des professeurs une interface simple et très intuitive leur permettant de générer les environnements de travaux pratiques rapidement et de manière réplicable.",
                                    "Ainsi, vos étudiants n'ont plus à installer et configurer ces environnements par eux-mêmes sur leur ordinateur personnel."]}/>
                        <Button title='Je souhaite tester'
                            display={window.innerWidth < 1100}/>
                    </div>

                    <div className='test-ctn'>
                        <div className={`emojis ${window.innerWidth >= 1100 ? 'not': ''}`}>
                            <img src="apple-emojis/justice.png"
                                alt='Emoji représentant la justice.'
                                className={`one ${current >= 4 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/justice.png"
                                alt='Emoji représentant la justice.'
                                className={`two ${current >= 4 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/justice.png"
                                alt='Emoji représentant la justice.'
                                className={`three ${current >= 4 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/justice.png"
                                alt='Emoji représentant la justice.'
                                className={`four ${current >= 4 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/justice.png"
                                alt='Emoji représentant la justice.'
                                className={`five ${current >= 4 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/justice.png"
                                alt='Emoji représentant la justice.'
                                className={`six ${current >= 4 ? "animated" : "not-animated"}`} />
                        </div>
                        <Card   title='Égalité des chances'
                                content={["Aujourd'hui les étudiants achètent un ordinateur avec des capacités physiques qui diffèrent selon leurs moyens financiers personnels.",
                                    "Avec Cirrus, tous les environnements sont strictement identiques et permettent à chacun de travailler dans des conditions optimales quelque soit l'ordinateur personnel utilisé."]}/>
                        <Button title='Je souhaite tester' />
                    </div>

                    <div className='test-ctn last'>
                        <div className={`emojis ${window.innerWidth >= 1100 ? 'not': ''}`}>
                            <img src="apple-emojis/thunder.png"
                                alt='Emoji éclair.'
                                className={`one ${current >= 5 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/thunder.png"
                                alt='Emoji éclair.'
                                className={`two ${current >= 5 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/thunder.png"
                                alt='Emoji éclair.'
                                className={`three ${current >= 5 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/thunder.png"
                                alt='Emoji éclair.'
                                className={`four ${current >= 5 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/thunder.png"
                                alt='Emoji éclair.'
                                className={`five ${current >= 5 ? "animated" : "not-animated"}`} />
                            <img src="apple-emojis/thunder.png"
                                alt='Emoji éclair.'
                                className={`six ${current >= 5 ? "animated" : "not-animated"}`} />
                        </div>
                        <Card   title='Une puissance inégalée'
                                content={["Le cloud est une ressource riche en puissance et en opportunités. Cependant, il est encore très compliqué de l'utiliser car cela requiert des formations et des compétences en informatique très élevées.",
                                    "Avec Cirrus, retrouvez toutes les opportunités du Cloud à la portée de toutes et tous."]}/>
                        <Button title='Je souhaite tester'
                            display={window.innerWidth < 1100}/>
                    </div>
                </div>
            </div>
        </div>
    );
}