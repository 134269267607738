import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL } from '../../modules/config.module';
import './Team.scss';

export default function Team() {

    return (
            <div className='Team'>
                <div className='card'>
                    <div className='title-ctn'>
                        <img src="apple-emojis/arm.png"
                            alt='Emoji bras musclé.'/>
                        <h1>La team Caelus</h1>
                        <img src="apple-emojis/arm-reversed.png"
                            alt='Emoji bras musclé.'/>
                    </div>
                    <div className='photos-ctn'>
                        <div className='single-ctn'>
                            <img src="team/benoit.png"
                                alt="Photo de Benoit Lefebvre"/>  
                            <p>Benoit</p> 
                        </div>
                        <div className='single-ctn'>
                            <img src="team/daphne.png"
                                alt="Photo de Daphné Urbanski"/>
                            <p>Daphné</p>
                        </div>
                        <div className='single-ctn'>
                            <img src="team/louis.png"
                                alt="Photo de Louis Vieillard"/>
                            <p>Louis</p>
                        </div>
                        <div className='single-ctn'>
                            <img src="team/peheux.png"
                                alt="Photo de Paul-Émile Vétu"/>
                            <p>Paul-Émile</p>
                        </div>
                    </div>
                    <h3>Suivez de près nos aventures</h3>
                    <div className='networks-ctn'>
                        <a href={INSTAGRAM_URL}
                            rel="noreferrer"
                            target='_blank'>
                            <img src="instagram.png" 
                                alt="Logo officiel d'Instagram"/>
                        </a>
                        <a href={LINKEDIN_URL}
                            rel="noreferrer"
                            target='_blank'>
                            <img src="linkedin.png" 
                                alt="Logo officiel de LinkedIn"/>
                        </a>
                        <a href={FACEBOOK_URL}
                            rel="noreferrer"
                            target='_blank'>
                            <img src="facebook.png" 
                                alt="Logo officiel de Facebook"/>
                        </a>
                    </div>
                </div>
                
            </div>
    );
}


